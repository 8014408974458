import React, { useState } from 'react';
import { Card, Row, Col, Table, NavDropdown } from 'react-bootstrap';
import { TextField, MenuItem, Button } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slider from '@mui/material/Slider';
import { useWidgetContext } from '../context/DragContext';


function SettingControls() {
    const [fromAccount, setFromAccount] = useState('AI Account');
    const [toAccount, setToAccount] = useState('AI Account');
    const [amount, setAmount] = useState('');
    const [selectedButton, setSelectedButton] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const { removeWidgetFromGrid } = useWidgetContext();

    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    const handleFromAccountChange = (event) => {
        setFromAccount(event.target.value);
    };

    const handleToAccountChange = (event) => {
        setToAccount(event.target.value);
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
    };

    const handleButtonClick = (buttonName) => {
        setSelectedButton(buttonName);
    };

    return (
        <Card className="main-card mt-3 home-chart">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between align-items-center mt-1">
                    <div className="d-flex align-items-center">
                        <h2 className="medium-heading mb-0">Settings Control</h2>
                        <div className='ai_block'>
                            <span className="ml-2">AI</span>
                        </div>
                    </div>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                            >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                            >
                            {/* <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('watchlist')}>Close Window</NavDropdown.Item> */}
                        </NavDropdown>
                    </div>
                </div>
                <span>Totality</span>
            </Card.Header>

            <Card.Body className="pt-0 p-2 mt-1">
                <form>
                    <Row className="mb-2">
                        <Col sm={6}>
                            {/* Dropdown for From Account */}
                            <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={fromAccount}
                                    onChange={handleFromAccountChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="square-select"
                                >
                                    <MenuItem value="AI Account"> From AI Account </MenuItem>
                                    <MenuItem value="Saving Account"> From Saving Account </MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    value={toAccount}
                                    onChange={handleToAccountChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="square-select"
                                >
                                    <MenuItem value="AI Account"> From AI Account </MenuItem>
                                    <MenuItem value="Saving Account"> From Saving Account </MenuItem>
                                </Select>
                            </FormControl>

                        </Col>
                    </Row>

                    <Row className="mb-2 control">
                        <Col sm={6}>
                            {/* Text Field for Amount */}
                            <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
                                <TextField
                                    placeholder="Amount"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    fullWidth
                                    margin="normal"
                                    type="text"

                                />
                            </FormControl>

                        </Col>
                        <Col sm={3}>
                            <Button
                                variant="outlined"
                                className={`ai-btn w-100 ${selectedButton === 'Deposit' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('Deposit')}
                                style={{
                                    backgroundColor: selectedButton === 'Deposit' ? 'rgba(49, 124, 255, 0.20)' : 'transparent',
                                    color: selectedButton === 'Deposit' ? '#FFFFFF' : '#898889'
                                }}
                            >
                                Deposit
                            </Button>
                        </Col>
                        <Col sm={3}>
                            <Button
                                variant="outlined"
                                className={`ai-btn w-100 ${selectedButton === 'Withdraw' ? 'active' : ''}`}
                                onClick={() => handleButtonClick('Withdraw')}
                                style={{
                                    backgroundColor: selectedButton === 'Withdraw' ? 'rgba(49, 124, 255, 0.20)' : 'transparent',
                                    color: selectedButton === 'Withdraw' ? '#FFFFFF' : '#898889'
                                }}
                            >
                                Withdraw
                            </Button>
                        </Col>
                    </Row>

                    {/* Submit Button */}
                    <div className="row justify-content-center">
                        <button type="submit" className="w-65 p-2 btn-primary" style={{color: "#FFF", border: "unset"}}>Submit</button>
                    </div>
                    

                    <hr className="mt-2 mb-2" />
                    <div>
                        <div style={{padding: '0px 10px'}}>
                            <span>Risk Management Settings</span><br></br>
                            <span>AI operation style</span>
                            <Slider
                                aria-label="Custom marks"
                                className="ai-slider"
                                defaultValue={30}
                                min={0}
                                max={100}
                                step={1}
                                sx={{
                                    "& .MuiSlider-track": {
                                        color: "#317CFF", // Selected portion color
                                    },
                                    "& .MuiSlider-rail": {
                                        color: "#d3d3d3", // Non-selected portion color
                                    },
                                }}
                            />
                        </div>
                        
                        <Row style={{ 'margin-top': '-10px', 'margin-bottom' : '8px'}}>
                            <Col sm={6} style={{ textAlign: 'left' }}>
                                <div>
                                    <span className="small-gray">Conservative</span>
                                </div>
                            </Col>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                                <div>
                                    <span className="small-gray">Progressive</span>
                                </div>
                            </Col>
                        </Row>

                        <Row className="mb-3 control align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Balance operated by AI</span>
                                </div>
                                <span className='small-gray'>35,454.22 Available</span>
                            </Col>
                            <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value="$ 23,732.77"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>

                            </Col>
                        </Row>

                        {/* <Row className="mb-3 control align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Execution Time</span>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <FormControl className="square-form-control m-0" sx={{ minWidth: 60 }}>
                                    <TextField
                                        value="3"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>
                            </Col>
                            <Col sm={3}>
                                <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 60 }}>
                                    <Select
                                        value={fromAccount}
                                        onChange={handleFromAccountChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="square-select"
                                    >
                                        <MenuItem value="AI Account"> months </MenuItem>
                                        <MenuItem value="Saving Account"> From Saving Account </MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row> */}

                        <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Stop - loss limit</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value="30 %"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>

                            </Col>
                        </Row>

                        <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Take - profit limit</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value="15 %"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>

                            </Col>
                        </Row>

                        <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Warning percentage</span>
                                </div>
                            </Col>
                            <Col sm={6}>
                            <FormControl className="square-form-control m-0" sx={{ minWidth: 120 }}>
                                    <TextField
                                        value="5 %"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>

                            </Col>
                        </Row>

                        {/* <Row className="mb-3 align-items-center">
                            <Col sm={6}>
                                <div>     
                                    <span>Report frequency</span>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <FormControl className="square-form-control m-0" sx={{ minWidth: 60 }}>
                                    <TextField
                                        value="2"
                                        fullWidth
                                        margin="normal"
                                        type="text"
                                        InputProps={{
                                            readOnly: true,
                                        }}                                
                                    />
                                </FormControl>
                            </Col>
                            <Col sm={3}>
                                <FormControl className="square-form-control m-0" sx={{ m: 1, minWidth: 60 }}>
                                    <Select
                                        value={fromAccount}
                                        onChange={handleFromAccountChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        className="square-select"
                                    >
                                        <MenuItem value="AI Account"> weeks </MenuItem>
                                        <MenuItem value="Saving Account"> From Saving Account </MenuItem>
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row> */}
                    </div>

                    <hr className="mt-2 mb-2" />
                    <div>
                        <Row>
                            <Col sm={6} style={{ textAlign: 'left' }}>
                                <div>
                                    <span>Allocation Preferences</span>
                                </div>
                            </Col>
                            <Col sm={6} style={{ textAlign: 'right' }}>
                                <div>
                                    <span className='small-gray'>Edit</span>
                                </div>
                            </Col>
                        </Row>
                        <div className="table-responsive scrollbar-custom m-h-320">
                            <Table hover className="transaction-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Date</th>
                                        <th>Portion</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Corn</td>
                                        <td>Jul, 2024</td>
                                        <td>27.50 %</td>
                                        <td>10,003,387.21</td>
                                    </tr>
                                    <tr>
                                        <td>Corn</td>
                                        <td>Dec, 2024</td>
                                        <td>14.30 %</td>
                                        <td>2,278.33</td>
                                    </tr>
                                    <tr>
                                        <td>Silver</td>
                                        <td>Nov, 2024</td>
                                        <td>12.20 %</td>
                                        <td>2,195.12</td>
                                    </tr>
                                    <tr>
                                        <td>Gold</td>
                                        <td>Jan, 2025</td>
                                        <td>11.50 %</td>
                                        <td>1,979.49</td>
                                    </tr>
                                    <tr>
                                        <td>Gold</td>
                                        <td>Dec, 2024</td>
                                        <td>8.65 %</td>
                                        <td>1,697.20</td>
                                    </tr>
                                    <tr>
                                        <td>Soybean</td>
                                        <td>Nov, 2024</td>
                                        <td>8.30 %</td>
                                        <td>1,650.33</td>
                                    </tr>
                                    <tr>
                                        <td>Gold</td>
                                        <td>Aug, 2024</td>
                                        <td>7.23 %</td>
                                        <td>1,480.12</td>
                                    </tr>
                                    <tr>
                                        <td>Corn</td>
                                        <td>Jan, 2025</td>
                                        <td>7.21 %</td>
                                        <td>1,460.22</td>
                                    </tr>
                                    <tr>
                                        <td>Crude Oil</td>
                                        <td>Jul, 2025</td>
                                        <td>3.79 %</td>
                                        <td>768,34</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                    
                </form>
                
            </Card.Body>
        </Card>
    );
}

export default SettingControls;
