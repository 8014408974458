import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [auth, setAuth] = useState({
        accessToken: localStorage.getItem('accessToken') || null,
        refreshToken: localStorage.getItem('refreshToken') || null,
        userId: localStorage.getItem('userId') || null,
    });
    const [userData, setUserData] = useState(null);
    const [historicalData, setHistoricalData] = useState([]);
    const [selectedValue, setSelectedValue] = useState('ZC|CBOT|202412');
    const [symbolData, setSymbolData] = useState(null);


    const login = (accessToken, refreshToken, userId) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        setAuth({ accessToken, refreshToken, userId });
        navigate('/Home/Landing');
    };

    const logout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userId');
        setAuth({ accessToken: null, refreshToken: null, userId:null });
        navigate('/');
    };

    // Function to fetch user data
    const fetchUserData = async () => {
        if (userData) return userData; // Use existing data if already fetched
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/${auth.userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                },
            });
            setUserData(response.data);
            return response.data;
        } catch (error) {
            console.error("Error fetching user data:", error);
            if (error.response?.status === 401) logout(); // Handle token expiration
        }
    };

    const initEmailChange = async (newemail) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/init-email-change`, 
                { email: newemail },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error initiating email change:", error); // Updated log message
            if (error.response?.status === 401) {
                logout(); // Handle token expiration
            }
            return null; // Return null or an error object for consistency
        }
    };


    const changeEmail = async (otp,newemail) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/change-email`, 
                { otp: otp, newEmail: newemail, userId: auth.userId },
                { headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.accessToken}`,
                }}
            );
            return response.data; // Return the response data on success
        } catch (error) {
            console.error("Error initiating email change:", error); // Updated log message
            if (error.response?.status === 401) {
                // logout(); // Handle token expiration
            }
            return null; // Return null or an error object for consistency
        }
    };
    
    // useEffect(() => {
    //     console.log(selectedValue);
        
    //     const [symbol, exchange, expiry] = selectedValue.split('|');
    //     const fetchHistoricalData = async () => {
    //         try {
    //             const url = `http://44.192.89.34/historical_data?symbol=${symbol}&exchange=${exchange}&expiry=${expiry}`;
    //             const response = await axios.get(url);
    //             if (response.data && Array.isArray(response.data.historical_data)) {
    //                 const formattedData = response.data.historical_data.map(item => ({
    //                     date: new Date(item.date).getTime() / 1000, // Convert date string to Unix timestamp
    //                     open: item.open,
    //                     high: item.high,
    //                     low: item.low,
    //                     close: item.close,
    //                     volume: item.volume,
    //                 }));
    //                 setHistoricalData(formattedData); 
    //             } else {
    //                 console.error('Expected historical_data array, but got:', response.data);
    //                 return [];
    //             }
    //         } catch (error) {
    //             console.error('Error fetching historical data:', error);
    //             return [];
    //         }
    //     };
    //     fetchHistoricalData();
    // }, [selectedValue]);

    // useEffect(() => {
    //     const [symbol, exchange, expiry] = selectedValue.split('|');
    //     const fetchRealTimeData = async () => {
    //         const url = `http://44.192.89.34/realtime_data?symbol=${symbol}&exchange=${exchange}&expiry=${expiry}`;
    //         const response = await axios.get(url);
            
    //         if (response.data) {
    //             setSymbolData(response.data);
    //             const formattedData = {
    //                 date: new Date(response.data.date_time).getTime() / 1000, // Convert date string to Unix timestamp
    //                 open: response.data.open,
    //                 high: response.data.high,
    //                 low: response.data.low,
    //                 close: response.data.close,
    //                 volume: response.data.volume,
    //             };
    //         } else {
    //             console.error('Expected historical_data array, but got:', response.data);
    //             return [];
    //         }
    //     };
    //     fetchRealTimeData();
    // }, []);

    return (
        <AuthContext.Provider value={{ auth, login, logout, fetchUserData, userData, initEmailChange, changeEmail, historicalData, selectedValue, setSelectedValue }}>
            {children}
        </AuthContext.Provider>
    );
};
