import React, { useState } from 'react';
import { Card, Table, NavDropdown } from 'react-bootstrap';
import Chart from 'react-apexcharts';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InvestmentDot from './InvestmentDot';
import { useWidgetContext } from '../context/DragContext';

function AccountOverview() {
  const [sort, setSort] = React.useState('category');
  const { removeWidgetFromGrid } = useWidgetContext();

  const investmentData = [
    { name: 'Gold', percentage: 72, color: '#317CFF' },
    { name: 'Silver', percentage: 4, color: '#E04662' },
    { name: 'Crude Oil', percentage: 13, color: '#40C796' },
    { name: 'Corn', percentage: 2, color: '#F4F1ED' },
    { name: 'Soybean', percentage: 9, color: '#FBD914' },
  ];

  const handleChange = (event) => {
    setSort(event.target.value);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggle = (isOpen) => {
    setShowDropdown(isOpen);
  };

  const handleSvgClick = () => {
    setShowDropdown(!showDropdown);
  };

  // Sample data for the donut chart
  const chartOptions = {
    series: [72, 13, 9, 4, 2], // Example data points
    options: {
      chart: {
        type: 'donut',
        height: '100%', // Make the chart responsive to the container height
      },
      labels: investmentData.map(item => item.name),
      colors: investmentData.map(item => item.color),
      stroke: {
        show: true,
        width: 0,
        colors: ['transparent'],
      },
      legend: {
        show: false,  // Hide the legend
      },
      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <Card className="main-card mt-3">
      <Card.Header className="pb-0">
        <div className="d-flex justify-content-between mt-1">
          <h2 className="medium-heading mb-0">Account Overview</h2>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={handleSvgClick}
              style={{ cursor: 'pointer' }}
            >
              <path
                d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                fill="white"
              />
              <path
                d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z"
                fill="white"
              />
              <path
                d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z"
                fill="white"
              />
            </svg>
            <NavDropdown
              show={showDropdown}
              onToggle={handleToggle}
              title=""
              id="nav-dropdown"
              align="end"
              className="d-inline custom-nav-dropdown"
              style={{ display: showDropdown ? 'block' : 'none' }}
            >
              <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('accountoverview')}>Close Window</NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
        <span className="small-gray">Jun 12, 2024 | 16:05</span>
      </Card.Header>
      <Card.Body className="pt-0 p-2 mt-1">
        <div className="table-responsive scrollbar-custom plr-2 w-t-div">
          <Table hover className="watchlist-table">
            <thead>
              <tr>
                <th className="gray-text">Balance</th>
                <th className="gray-text">Invested</th>
                <th className="text-end gray-text">P/L</th>
                <th className="text-end gray-text">P/L %</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>99,843.40</td>
                <td>81,770.32</td>
                <td className="text-success text-end align-middle">2,923.81</td>
                <td className="text-success text-end align-middle">13.32%</td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div className="gray-text mt-1" style={{marginLeft: '10px'}}>Investment</div>
        <div className="row mt-1 justify-content-between">
          <div className="col-4 p-0" style={{ marginLeft: '-25px' }}>
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="donut"
              width="139%" // Adjusted width
            />
          </div>
          <div className="col-8">
            <FormControl className="custom-form-control m-0" sx={{ m: 1, minWidth: 120 }}>
              <Select
                value={sort}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                className="custom-select"
              >
                <MenuItem value="category"> Sort by category </MenuItem>
                <MenuItem value="amount"> Sort by amount </MenuItem>
                <MenuItem value="performance"> Sort by performance </MenuItem>
              </Select>
            </FormControl>
            
            <table className="w-100 mt-2">
              <tbody>
                {investmentData.map((investment, index) => {
                  // Create rows for two investments
                  if (index % 2 === 0) {
                    return (
                      <tr key={index}>
                        <td>
                          <InvestmentDot color={investment.color} />
                          <span className="gray-text">{investment.name}</span>
                        </td>
                        <td>
                          <span className="bold">{investment.percentage}%</span>
                        </td>
                        {/* Check if there is a next investment to display in the same row */}
                        {investmentData[index + 1] ? (
                          <>
                            <td>
                              <InvestmentDot color={investmentData[index + 1].color} />
                              <span className="gray-text">{investmentData[index + 1].name}</span>
                            </td>
                            <td>
                              <span className="bold">{investmentData[index + 1].percentage}%</span>
                            </td>
                          </>
                        ) : (
                          // If there's no next investment, fill empty cells
                          <>
                            <td></td>
                            <td></td>
                          </>
                        )}
                      </tr>
                    );
                  }
                  return null; // For odd indices, we don't need to render anything
                })}
              </tbody>
            </table>
            
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default AccountOverview;

