import React, { useEffect, useRef, useState,useContext } from 'react';
import { createChart } from 'lightweight-charts';
import { Card, NavDropdown, ButtonGroup, Button, Spinner } from 'react-bootstrap';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import io from 'socket.io-client';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Popover } from '@mui/material';
import Switch from './Switch';
import { AuthContext } from '../context/AuthContext';
import { useWidgetContext } from '../context/DragContext';

const TradingChart = () => {
  const { historicalData, selectedValue, setSelectedValue } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [selectedValue, setSelectedValue] = useState('ZC|CBOT|202412');
    const [loading, setLoading] = useState(true);
    const { removeWidgetFromGrid } = useWidgetContext();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelectChange = (event) => {
      const value = event.target.value;
      console.log(value);
      
      setSelectedValue(value);
    };

    // useEffect(() => {
    //   console.log(historicalData);
      
    //   setInitialData(historicalData);
    //   setLoading(false);
    // }, [historicalData]);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const initialSwitchState = {
        VWAP: true,
        movingAverages: true,
        volume: true,
        stochastics: false,
    };

    // State to track the status of all switches
    const [switchStates, setSwitchStates] = useState(initialSwitchState);

    // Handle switch toggle for a specific indicator
    const handleToggleSwitch = (indicator) => {
        setSwitchStates((prevState) => ({
            ...prevState,
            [indicator]: !prevState[indicator], // Toggle the switch state for the specific indicator
        }));
    };

    const chartContainerRef = useRef(null);
    const candleSeriesRef = useRef(null);
    const volumeSeriesRef = useRef(null);
    const socketRef = useRef(null);

    const [showDropdown, setShowDropdown] = useState(false);
    // const [symbol, setSymbol] = useState('GC.FUT'); // Default symbol
    const [symbol, setSymbol] = useState('GC.FUT'); // Default symbol
    const [initialData, setInitialData] = useState([
        { time: '2024-09-01', open: 26058, high: 26180, low: 26000, close: 26020, volume: 10000 },
        { time: '2024-09-02', open: 26000, high: 26100, low: 25900, close: 26050, volume: 12000 },
        { time: '2024-09-03', open: 26060, high: 26150, low: 25950, close: 26100, volume: 9000 },
        { time: '2024-09-04', open: 26170, high: 26260, low: 26100, close: 26220, volume: 15000 },
        { time: '2024-09-05', open: 26210, high: 26300, low: 26150, close: 26280, volume: 11000 },
        { time: '2024-09-06', open: 26130, high: 26200, low: 26050, close: 26180, volume: 14000 },
        { time: '2024-09-07', open: 26270, high: 26350, low: 26200, close: 26260, volume: 13000 },
        { time: '2024-09-08', open: 26360, high: 26450, low: 26300, close: 26420, volume: 16000 },
        { time: '2024-09-09', open: 26410, high: 26500, low: 26350, close: 26470, volume: 17000 },
        { time: '2024-09-10', open: 26310, high: 26400, low: 26260, close: 26370, volume: 9000 }
    ]);
    const handleToggle = (isOpen) => {
        setShowDropdown(isOpen);
    };

    const handleSvgClick = () => {
        setShowDropdown(!showDropdown);
    };

    // useEffect(() => {
    //     // Initialize chart
    //     const chart = createChart(chartContainerRef.current, {
    //         width: chartContainerRef.current.clientWidth,
    //         // height: 900,
    //         layout: {
    //             background: { color: 'rgba(31, 32, 37, 0.70)' },
    //             textColor: '#DDD',
    //         },
    //         grid: {
    //             vertLines: { color: 'transparent' },
    //             horzLines: { color: '#444' },
    //         },
    //         crosshair: {
    //             mode: 0, // LightweightCharts.CrosshairMode.Normal
    //         },
    //         rightPriceScale: {
    //             borderColor: '#cccccc',
    //         },
    //         timeScale: {
    //             borderColor: '#cccccc',
    //         },
    //     });

    //     function calculateMovingAverageSeriesData(candleData, maLength) {
    //         const maData = [];

    //         for (let i = 0; i < candleData.length; i++) {
    //             if (i < maLength) {
    //                 // Provide whitespace data points until the MA can be calculated
    //                 maData.push({ time: candleData[i].time });
    //             } else {
    //                 // Calculate the moving average, slow but simple way
    //                 let sum = 0;
    //                 for (let j = 0; j < maLength; j++) {
    //                     sum += candleData[i - j].close;
    //                 }
    //                 const maValue = sum / maLength;
    //                 maData.push({ time: candleData[i].time, value: maValue });
    //             }
    //         }

    //         return maData;
    //     }

    //     const convertedData = historicalData.map(item => ({
    //         time: new Date(item.date).getTime() / 1000, // Convert date string to Unix timestamp
    //         open: item.open,
    //         high: item.high,
    //         low: item.low,
    //         close: item.close,
    //         volume: item.volume
    //     }));
    //     // console.log(convertedData)

    //     const maData = calculateMovingAverageSeriesData(historicalData, 20);

    //     // const maSeries = chart.addLineSeries({ color: '#2962FF', lineWidth: 1 });
    //     // maSeries.setData(maData);

    //     // Candlestick series
    //     candleSeriesRef.current = chart.addCandlestickSeries({
    //         upColor: '#40C796',        // Color for bullish candles
    //         downColor: '#E04662',      // Color for bearish candles
    //         borderUpColor: '#40C796',  // Border color for bullish candles
    //         borderDownColor: '#E04662',// Border color for bearish candles
    //         wickUpColor: '#40C796',    // Wick color for bullish candles
    //         wickDownColor: '#E04662',  // Wick color for bearish candles
    //     });

    //     // Volume series
    //     volumeSeriesRef.current = chart.addHistogramSeries({
    //         color: '#26a69a',
    //         priceFormat: {
    //             type: 'volume',
    //         },
    //         scaleMargins: {
    //             top: 0.7,
    //             bottom: 0,
    //         },
    //     });

    //     // Set initial data
    //     // candleSeriesRef.current.setData(historicalData);
    //     candleSeriesRef.current.setData(convertedData);
    //     // volumeSeriesRef.current.setData(historicalData.map(item => ({
    //     //     time: new Date(item.date).getTime() / 1000,
    //     //     value: item.volume,
    //     //     color: item.close > item.open ? '#40C796' : '#E04662', // Green if close > open, else red
    //     // })));
    //     chart.timeScale().fitContent();

    //     return () => {
    //         chart.remove();
    //     };
    // }, [historicalData]);

    // useEffect(() => {
    //     // Establish WebSocket connection
    //     socketRef.current = io('http://44.192.89.34:5000');

    //     // Subscribe to OHLCV data
    //     subscribeOhlcv(symbol);

    //     // Cleanup on component unmount
    //     return () => {
    //         socketRef.current.disconnect();
    //     };
    // }, [symbol]);

    // const subscribeOhlcv = (symbol) => {
    //     console.log(symbol);
    //     socketRef.current.emit('subscribe_ohlcv', {
    //         symbol: symbol,
    //         schema: 'ohlcv-1s',
    //     });

    //     socketRef.current.on('ohlcv_data', (data) => {
    //         const timestampInMilliseconds = data.timestamp / 1e6;
    //         const date = new Date(timestampInMilliseconds);
    //         const formattedDate = date.toISOString().split('T')[0];
    //         const normalizationFactor = 1e8;

    //         const formattedData = {
    //             time: formattedDate,
    //             open: data.open / normalizationFactor,
    //             high: data.high / normalizationFactor,
    //             low: data.low / normalizationFactor,
    //             close: data.close / normalizationFactor,
    //             volume: data.volume, // Add volume data
    //         };

    //         setInitialData((prevData) => {
    //             const lastItem = prevData[prevData.length - 1];
    //             if (lastItem && lastItem.time === formattedData.time) {
    //                 // Update the last item if time is the same
    //                 const updatedData = prevData.slice(0, -1).concat(formattedData);
    //                 candleSeriesRef.current.setData(updatedData);
    //                 volumeSeriesRef.current.setData(updatedData.map(item => ({
    //                     time: item.time,
    //                     value: item.volume,
    //                     color: item.close > item.open ? '#40C796' : '#E04662',
    //                 })));
    //                 return updatedData;
    //             } else {
    //                 // Add the new data point
    //                 const updatedData = [...prevData, formattedData];
    //                 candleSeriesRef.current.setData(updatedData);
    //                 volumeSeriesRef.current.setData(updatedData.map(item => ({
    //                     time: item.time,
    //                     value: item.volume,
    //                     color: item.close > item.open ? '#40C796' : '#E04662',
    //                 })));
    //                 return updatedData;
    //             }
    //         });
    //     });
    // };

    useEffect(() => {
        // Initialize chart
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            // height: 900,
            layout: {
                background: { color: 'rgba(31, 32, 37, 0.70)' },
                textColor: '#DDD',
            },
            grid: {
                vertLines: { color: 'transparent' },
                horzLines: { color: '#444' },
            },
            crosshair: {
                mode: 0, // LightweightCharts.CrosshairMode.Normal
            },
            rightPriceScale: {
                borderColor: '#cccccc',
            },
            timeScale: {
                borderColor: '#cccccc',
            },
        });

        function calculateMovingAverageSeriesData(candleData, maLength) {
            const maData = [];

            for (let i = 0; i < candleData.length; i++) {
                if (i < maLength) {
                    // Provide whitespace data points until the MA can be calculated
                    maData.push({ time: candleData[i].time });
                } else {
                    // Calculate the moving average, slow but simple way
                    let sum = 0;
                    for (let j = 0; j < maLength; j++) {
                        sum += candleData[i - j].close;
                    }
                    const maValue = sum / maLength;
                    maData.push({ time: candleData[i].time, value: maValue });
                }
            }

            return maData;
        }

        const maData = calculateMovingAverageSeriesData(initialData, 20);

        const maSeries = chart.addLineSeries({ color: '#2962FF', lineWidth: 1 });
        maSeries.setData(maData);

        // Candlestick series
        candleSeriesRef.current = chart.addCandlestickSeries({
            upColor: '#40C796',        // Color for bullish candles
            downColor: '#E04662',      // Color for bearish candles
            borderUpColor: '#40C796',  // Border color for bullish candles
            borderDownColor: '#E04662',// Border color for bearish candles
            wickUpColor: '#40C796',    // Wick color for bullish candles
            wickDownColor: '#E04662',  // Wick color for bearish candles
        });

        // Volume series
        volumeSeriesRef.current = chart.addHistogramSeries({
            color: '#26a69a',
            priceFormat: {
                type: 'volume',
            },
            scaleMargins: {
                top: 0.7,
                bottom: 0,
            },
        });

        // Set initial data
        candleSeriesRef.current.setData(initialData);
        volumeSeriesRef.current.setData(initialData.map(item => ({
            time: item.time,
            value: item.volume,
            color: item.close > item.open ? '#40C796' : '#E04662', // Green if close > open, else red
        })));
        // chart.timeScale().fitContent();

        return () => {
            chart.remove();
        };
    }, [initialData]);


    const handleSymbolChange = (event) => {
        setSymbol(event.target.value);
    };

    return (
        <Card className="main-card home-chart mt-3">
            <Card.Header className="pb-0">
                <div className="d-flex justify-content-between">
                    <FormControl className="m-0" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <Select
                            className="my-select"
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={selectedValue}
                            onChange={handleSelectChange}
                            IconComponent={ExpandMoreIcon} // Custom Icon
                            sx={{
                                '.MuiSelect-icon': {
                                    color: 'white',
                                    fontSize: '2rem',
                                    right: '5px',
                                },
                                paddingRight: '2px',
                            }}
                        >
                            {/* <MenuItem value="">
                                <em>None</em>
                            </MenuItem> */}
                            <MenuItem value={"ZC|CBOT|202412"}>Corn (ZC)</MenuItem>
                            <MenuItem value={"CL|NYMEX|202412"}>Crude Oil (CL)</MenuItem>
                            <MenuItem value={"GC|COMEX|202412"}>Gold (GC)</MenuItem>
                            <MenuItem value={"SI|COMEX|202412"}>Silver (SI)</MenuItem>
                            <MenuItem value={"ZS|CBOT|202412"}>Soybean (ZS)</MenuItem>

                        </Select>
                    </FormControl>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            onClick={handleSvgClick}
                            style={{ cursor: 'pointer' }}
                        >
                            <path d="M12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5006 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z" fill="white" />
                            <path d="M18.75 13.5C18.4533 13.5 18.1633 13.412 17.9166 13.2472C17.67 13.0824 17.4777 12.8481 17.3642 12.574C17.2506 12.2999 17.2209 11.9983 17.2788 11.7074C17.3367 11.4164 17.4796 11.1491 17.6893 10.9393C17.8991 10.7296 18.1664 10.5867 18.4574 10.5288C18.7483 10.4709 19.0499 10.5006 19.324 10.6142C19.5981 10.7277 19.8324 10.92 19.9972 11.1666C20.162 11.4133 20.25 11.7033 20.25 12C20.25 12.3978 20.092 12.7794 19.8107 13.0607C19.5294 13.342 19.1478 13.5 18.75 13.5Z" fill="white" />
                            <path d="M5.25 13.5C4.95333 13.5 4.66332 13.412 4.41665 13.2472C4.16997 13.0824 3.97771 12.8481 3.86418 12.574C3.75065 12.2999 3.72094 11.9983 3.77882 11.7074C3.8367 11.4164 3.97956 11.1491 4.18934 10.9393C4.39912 10.7296 4.66639 10.5867 4.95737 10.5288C5.24834 10.4709 5.54994 10.5006 5.82403 10.6142C6.09812 10.7277 6.33238 10.92 6.49721 11.1666C6.66203 11.4133 6.75 11.7033 6.75 12C6.75 12.3978 6.59197 12.7794 6.31066 13.0607C6.02936 13.342 5.64783 13.5 5.25 13.5Z" fill="white" />
                        </svg>
                        <NavDropdown
                            show={showDropdown}
                            onToggle={handleToggle}
                            title=""
                            id="nav-dropdown"
                            align="end"
                            className="d-inline custom-nav-dropdown"
                            style={{ display: showDropdown ? 'block' : 'none' }}
                        >
                            <NavDropdown.Item className="dropdown-option" onClick={() => removeWidgetFromGrid('chart')}>Close Window</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
                <ButtonGroup className="chart-buttons">
                    <select onChange={handleSymbolChange} className='d-none'>
                        <option value="ZC.FUT">Corn (ZC)</option>
                        <option value="GC.FUT">Gold (GC)</option>
                        <option value="CL.FUT">Crude Oil (CL)</option>
                    </select>
                    <Button className="active ml-0">
                        1D
                    </Button>
                    <Button>
                        5D
                    </Button>
                    <Button>
                        1M
                    </Button>
                    <Button>
                        6M
                    </Button>
                    <Button className="">
                        YTD
                    </Button>
                    <Button>
                        5Y
                    </Button>
                    <Button>
                        10Y
                    </Button>
                </ButtonGroup>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                        <img src={`${process.env.PUBLIC_URL}/images/candle-bar.svg`} alt="candle-bar" className="cursor-pointer" />

                        <Button className="indicator-btn" onClick={handleClick}>
                            <img src={`${process.env.PUBLIC_URL}/images/indicator.svg`} alt="indicator" /> Indicators
                        </Button>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <div style={{ width: '595px', padding: '16px', color: '#FFF' }} className="indicator-switch">
                                <input type="text" placeholder="Search" className="form-control indicator-input" />
                                <div className="row mt-2">
                                    <div className="col-4 border-right">
                                        <ul>
                                            <li className="gray-text">All Indicators</li>
                                            <li className="gray-text">Favorites</li>
                                        </ul>

                                        <hr />

                                        <span className="gray-text">Categories</span>

                                        <ul className="indicator-cate-ul">
                                            <li>Moving Averages</li>
                                            <li>Momentum Indicators</li>
                                            <li>Volume Indicators</li>
                                            <li>Trend Indicators</li>
                                            <li>Volatility Indicators</li>
                                            <li>Support & Resistance Indicators</li>
                                            <li>Valuation Metrics</li>
                                            <li>Financial Health</li>
                                            <li>Profitability Metrics</li>
                                            <li>Growth Metrics</li>
                                            <li>Liquidity Metrics</li>
                                        </ul>
                                    </div>
                                    <div className="col-8">
                                        <span className="gray-text">Current indicators</span>

                                        <ul>
                                            <li className="d-flex justify-content-between">
                                                VWAP
                                                <Switch
                                                    isOn={switchStates.VWAP}
                                                    handleToggle={() => handleToggleSwitch('VWAP')}
                                                />
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                Moving averages
                                                <Switch
                                                    isOn={switchStates.movingAverages}
                                                    handleToggle={() => handleToggleSwitch('movingAverages')}
                                                />
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                Volume
                                                <Switch
                                                    isOn={switchStates.volume}
                                                    handleToggle={() => handleToggleSwitch('volume')}
                                                />
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                Stochastics
                                                <Switch
                                                    isOn={switchStates.stochastics}
                                                    handleToggle={() => handleToggleSwitch('stochastics')}
                                                />
                                            </li>
                                            <li className="d-flex justify-content-between">
                                                Stochastics
                                                <Switch
                                                    isOn={switchStates.stochastics}
                                                    handleToggle={() => handleToggleSwitch('stochastics')}
                                                />
                                            </li>
                                        </ul>

                                        <hr />

                                        <ul>
                                            <li>Simple Moving Average (SMA)</li>
                                            <li>Exponential Moving Average (EMA)</li>
                                            <li>Weighted Moving Average (WMA)</li>
                                        </ul>
                                        
                                    </div>
                                </div>

                            </div>
                        </Popover>
                    </div>

                    <img src={`${process.env.PUBLIC_URL}/images/gear.svg`} alt="gear-icon" className="cursor-pointer ml-auto" />
                </div>

            </Card.Header>
            <Card.Body className="pt-0">
            {/* {loading ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
            <Spinner animation="border" variant="light" />
          </div>
        ) : (
          <div ref={chartContainerRef} style={{ width: "100%", height: '100%' }} />
        )} */}
        <div ref={chartContainerRef} style={{ width: "100%", height: '100%' }} />
            </Card.Body>
        </Card>
    );
};

export default TradingChart;

