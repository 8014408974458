// ProgressBar.js
import React from 'react';

const ProgressBarRiskLevel = ({ percentage }) => {
  return (
    <div className="">
     <div className="progress-bar-container-ai mt-1">
          <div
            className={`filler ${percentage >= 50 ? 'red' : ''}`}
            style={{ width: `${percentage}%` }}
          >
            {/* Add the cross line */}
            <div className="cross-line"></div>
          </div>
        </div>
      <div className="progress-container">
        <span className="small-gray">Safe</span>
        
        <span className="small-gray right">Dangerous</span>
      </div>
    </div>
  );
};

export default ProgressBarRiskLevel;
